.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #0F1632;
    padding: 10px;
    font-size: 25px;
    position: sticky;
    top: 0;
    z-index: 500;
  }
  
  .navbar-logo {
    font-weight: bold;
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    padding: 10px;
  }
  
  /* Media query for desktop */
  @media screen and (min-width: 768px) {
    .navbar-mobile-toggle {
      display: none;
    }
  
    .navbar-links {
      display: flex;
    }
  }
  
  /* Media query for mobile */
  @media screen and (max-width: 767px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      background-color: #282c34;
      text-align: center;
    }
  
    .navbar-links.mobile-menu-open {
      display: flex;
    }
  
    .navbar-links a {
      padding: 10px;
      border-bottom: 1px solid #282c34;
    }
  }
  