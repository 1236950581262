.card {
    width: 300px;
    height: 400px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .card-flip {
    width: 375px;
    height: 515px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .cardoverlay:hover .card-flip {
    display: block;
  }
  
  .card-image {
    width: 80%;
    object-fit: cover;
    max-height: 200px;
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  
  .card-content {
    text-align: center;
    padding: 16px;
  }
  
  .card-title {
    font-size: 25px;
    margin: 0;
  }

  .card-title-large {
    font-size: 25px;
    margin: 0;
  }
  
  .card-description {
    margin-top: 8px;
    font-size: 14px;
  }

  .card-description-large {
    margin-top: 8px;
    font-size: 14px;
  }
  
  .card-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .card-button:hover {
    background-color: #0056b3;
  }

  .flip-card {
    perspective: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
  }
  
  .flip-card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
  }
  
  .flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    color: white;
    overflow: hidden; /* Prevent content overflow */
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
    white-space: pre-line;
  }

  .card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border: 5px solid #007bff;
  }

  .card-flip:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border: 5px solid #007bff;
  }

  