.link-button {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    font-size: 36px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .link-icon {
    margin-right: 16px;
    font-size: 42px;
  }
  
  @media (max-width: 650px) {
    .link-button {
      padding: 10px 20px;
      font-size: 24px;
    }
  
    .link-icon {
      margin-right: 8px;
      font-size: 28px;
    }
  }
  